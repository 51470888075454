import { useBreakpoints, breakpointsTailwind } from "@vueuse/core";
import { reactive } from "vue";

const breakpoints = useBreakpoints({
  xs: 400,
  ...breakpointsTailwind,
  "2xl": 1440,
});

export function useBp(): Record<
  "greater" | "smaller",
  Record<"xs" | "sm" | "md" | "lg" | "xl" | "2xl", boolean>
> {
  return reactive({
    greater: {
      xs: breakpoints.greater("xs"),
      sm: breakpoints.greater("sm"),
      md: breakpoints.greater("md"),
      lg: breakpoints.greater("lg"),
      xl: breakpoints.greater("xl"),
      "2xl": breakpoints.greater("2xl"),
    },
    smaller: {
      xs: breakpoints.smaller("xs"),
      sm: breakpoints.smaller("sm"),
      md: breakpoints.smaller("md"),
      lg: breakpoints.smaller("lg"),
      xl: breakpoints.smaller("xl"),
      "2xl": breakpoints.smaller("2xl"),
    },
  });
}
