import api from "@/services/api";
import { assertUnreachable, QueryFunctionContext } from "@/utils/typing";
import { IconName } from "@/services/icons";
import { reactive, DeepReadonly, Ref } from "vue";
import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";
import {
  useManageResourcesTracking,
  useManageResourceGroupsTracking,
} from "@/services/gtm";
import { circumventAdBlocker } from "@/services/utils";
import {
  PublicDashboardFilters,
  usePublicDashboardQueryFilters,
} from "@/services/public_dashboard";

const gtmResource = useManageResourcesTracking();
const gtmResourceGroup = useManageResourceGroupsTracking();

export enum ResourceChannel {
  // These MUST reflect the Channel enum in the backend, AT ALL TIMES
  // See backend/resources/enums.py
  // If you need to display the channel name to a user, use channelDetails[ResourceChannel.<channel>].humanName
  FACEBOOK_ADS = "FacebookAds",
  FACEBOOK_ORGANIC = "FacebookOrganic",
  INSTAGRAM_ORGANIC = "InstagramOrganic",
  GOOGLE_SHEETS = "GoogleSheets",
  GOOGLE_ADS = "GoogleAds",
  GOOGLE_ANALYTICS = "GoogleAnalytics",
  GOOGLE_ANALYTICS_4 = "GoogleAnalytics4",
  GOOGLE_BIG_QUERY = "GoogleBigQuery",
  GOOGLE_BIG_QUERY_ANALYTICS = "GoogleBigQueryAnalytics",
  DV_360 = "DV360",
  MERCHANT_CENTER = "MerchantCenter",
  GOOGLE_SEARCH_CONSOLE = "GoogleSearchConsole",
  YOUTUBE = "Youtube",
  LINKED_IN_ADS = "LinkedInAds",
  LINKED_IN_ORGANIC = "LinkedInOrganic",
  SNAPCHAT_ADS = "SnapchatAds",
  XANDR = "Xandr",
  BING_ADS = "BingAds",
  C_ALT = "CAlt",
  TIK_TOK_ADS = "TikTokAds",
  TIK_TOK_ORGANIC = "TikTokOrganic",
  KOBLER = "Kobler",
  ADFORM = "Adform",
}

export const disabledChannels: ResourceChannel[] = [
  ResourceChannel.GOOGLE_BIG_QUERY_ANALYTICS,
];

export const hiddenChannels: ResourceChannel[] = [
  ...disabledChannels,
  ResourceChannel.GOOGLE_SHEETS,
];

export const restrictedChannels: ResourceChannel[] = [...hiddenChannels];

export interface ChannelDetail {
  name: ResourceChannel;
  backgroundColor: string;
  iconName: IconName;
  iconColor?: string;
  humanName: string;
}

export const channelDetails: Record<ResourceChannel, ChannelDetail> = {
  [ResourceChannel.FACEBOOK_ADS]: {
    name: ResourceChannel.FACEBOOK_ADS,
    backgroundColor: "bg-sky-600",
    iconName: "meta",
    iconColor: "text-facebook-blue",
    humanName: "Meta Ads",
  },
  [ResourceChannel.FACEBOOK_ORGANIC]: {
    name: ResourceChannel.FACEBOOK_ORGANIC,
    backgroundColor: "bg-sky-600",
    iconName: "facebook",
    iconColor: "text-facebook-blue",
    humanName: "Facebook",
  },
  [ResourceChannel.INSTAGRAM_ORGANIC]: {
    name: ResourceChannel.INSTAGRAM_ORGANIC,
    backgroundColor: "bg-pink-700",
    iconName: "instagram",
    iconColor: "text-pink-700",
    humanName: "Instagram",
  },
  [ResourceChannel.GOOGLE_ADS]: {
    name: ResourceChannel.GOOGLE_ADS,
    backgroundColor: "bg-teal-600",
    iconName: "google-ads",
    humanName: "Google Ads",
  },
  [ResourceChannel.GOOGLE_ANALYTICS]: {
    name: ResourceChannel.GOOGLE_ANALYTICS,
    backgroundColor: "bg-orange-500",
    iconName: "google-analytics",
    humanName: "Google Analytics",
  },
  [ResourceChannel.GOOGLE_ANALYTICS_4]: {
    name: ResourceChannel.GOOGLE_ANALYTICS_4,
    backgroundColor: "bg-orange-500",
    iconName: "google-analytics",
    humanName: "Google Analytics 4",
  },
  [ResourceChannel.GOOGLE_BIG_QUERY]: {
    name: ResourceChannel.GOOGLE_BIG_QUERY,
    backgroundColor: "bg-blue-500",
    iconName: "google-analytics",
    humanName: "Google BigQuery",
  },
  [ResourceChannel.GOOGLE_BIG_QUERY_ANALYTICS]: {
    name: ResourceChannel.GOOGLE_BIG_QUERY_ANALYTICS,
    backgroundColor: "bg-red-500",
    iconName: "google-analytics",
    humanName: "Google BigQuery Analytics",
  },
  [ResourceChannel.GOOGLE_SEARCH_CONSOLE]: {
    name: ResourceChannel.GOOGLE_SEARCH_CONSOLE,
    backgroundColor: "bg-violet-500",
    iconName: "google-search-console",
    humanName: "Google Search Console",
  },
  [ResourceChannel.YOUTUBE]: {
    name: ResourceChannel.YOUTUBE,
    backgroundColor: "bg-red-600",
    iconName: "youtube",
    iconColor: "text-youtube-red",
    humanName: "YouTube",
  },
  [ResourceChannel.MERCHANT_CENTER]: {
    name: ResourceChannel.MERCHANT_CENTER,
    backgroundColor: "bg-blue-600",
    iconName: "google-merchant-center",
    iconColor: "text-blue-600",
    humanName: "Google Merchant Center",
  },
  [ResourceChannel.DV_360]: {
    name: ResourceChannel.DV_360,
    backgroundColor: "bg-green-600",
    iconName: "google-dv360",
    iconColor: "text-green-600",
    humanName: "Google DV360",
  },
  [ResourceChannel.LINKED_IN_ADS]: {
    name: ResourceChannel.LINKED_IN_ADS,
    backgroundColor: "bg-linkedin-blue",
    iconName: "linked-in",
    iconColor: "text-linkedin-blue",
    humanName: "LinkedIn Ads",
  },
  // TODO: Differentiate it from LINKED_IN_ADS?
  [ResourceChannel.LINKED_IN_ORGANIC]: {
    name: ResourceChannel.LINKED_IN_ORGANIC,
    backgroundColor: "bg-blue-700",
    iconName: "linked-in",
    iconColor: "text-linkedin-blue",
    humanName: "LinkedIn",
  },
  [ResourceChannel.SNAPCHAT_ADS]: {
    name: ResourceChannel.SNAPCHAT_ADS,
    backgroundColor: "bg-amber-700",
    iconName: "snapchat-ghost",
    humanName: "Snapchat Ads",
  },
  [ResourceChannel.XANDR]: {
    name: ResourceChannel.XANDR,
    backgroundColor: "bg-xandr-red",
    iconName: "xandr",
    humanName: "Xandr",
  },
  [ResourceChannel.BING_ADS]: {
    name: ResourceChannel.BING_ADS,
    backgroundColor: "bg-green-600",
    iconName: "microsoft",
    iconColor: "text-bing-teal",
    humanName: "Microsoft Ads",
  },
  [ResourceChannel.C_ALT]: {
    name: ResourceChannel.C_ALT,
    backgroundColor: "bg-cyan-700",
    iconName: "circle-dot",
    iconColor: "text-cyan-700",
    humanName: "C·ALT",
  },
  [ResourceChannel.TIK_TOK_ADS]: {
    name: ResourceChannel.TIK_TOK_ADS,
    backgroundColor: "bg-pink-600",
    iconName: "tik-tok",
    iconColor: "text-black",
    humanName: "TikTok Ads",
  },
  [ResourceChannel.TIK_TOK_ORGANIC]: {
    name: ResourceChannel.TIK_TOK_ORGANIC,
    backgroundColor: "bg-pink-600",
    iconName: "tik-tok",
    iconColor: "text-black",
    humanName: "TikTok",
  },
  [ResourceChannel.KOBLER]: {
    name: ResourceChannel.KOBLER,
    backgroundColor: "bg-yellow-700",
    iconName: "kobler",
    humanName: "Kobler",
  },
  [ResourceChannel.ADFORM]: {
    name: ResourceChannel.ADFORM,
    backgroundColor: "bg-blue-700",
    iconName: "adform",
    humanName: "Adform",
  },
  [ResourceChannel.GOOGLE_SHEETS]: {
    name: ResourceChannel.GOOGLE_SHEETS,
    backgroundColor: "bg-green-700",
    iconName: "googleSheets",
    humanName: "Google Sheets",
  },
};

export interface BaseResource {
  createdAt: string;
  externalId: string;
  isInaccessible: boolean;
  isRemoved: boolean;
  modifiedAt: string;
  manuallyFetchedAt: string | null;
  canFetchManually: boolean;
  pk: number;
  token: number;
  user: number;
  name: string;
}

export interface FacebookAdsResource extends BaseResource {
  channel: ResourceChannel.FACEBOOK_ADS;
  metadata: Record<string, never>;
}

export interface FacebookOrganicResource extends BaseResource {
  channel: ResourceChannel.FACEBOOK_ORGANIC;
  metadata: { profileImageUrl?: string };
}

export interface InstagramOrganicResource extends BaseResource {
  channel: ResourceChannel.INSTAGRAM_ORGANIC;
  metadata: { profileImageUrl?: string; username?: string };
}

export interface GoogleAdsResource extends BaseResource {
  channel: ResourceChannel.GOOGLE_ADS;
  metadata: Record<string, never>;
}

export interface GoogleAnalyticsResource extends BaseResource {
  channel: ResourceChannel.GOOGLE_ANALYTICS;
  metadata: { hasEcommerce?: boolean };
}

export interface GoogleAnalytics4Resource extends BaseResource {
  channel: ResourceChannel.GOOGLE_ANALYTICS_4;
  metadata: Record<string, never>;
}

export interface GoogleBigQueryResource extends BaseResource {
  channel: ResourceChannel.GOOGLE_BIG_QUERY;
  metadata: Record<string, never>;
}

export interface GoogleBigQueryAnalyticsResource extends BaseResource {
  channel: ResourceChannel.GOOGLE_BIG_QUERY_ANALYTICS;
  metadata: Record<string, never>;
}

export interface GoogleSearchConsoleResource extends BaseResource {
  channel: ResourceChannel.GOOGLE_SEARCH_CONSOLE;
  metadata: Record<string, never>;
}

export interface YoutubeResource extends BaseResource {
  channel: ResourceChannel.YOUTUBE;
  metadata: Record<string, never>;
}

export interface MerchantCenterResource extends BaseResource {
  channel: ResourceChannel.MERCHANT_CENTER;
  metadata: Record<string, never>;
}

export interface DV360Resource extends BaseResource {
  channel: ResourceChannel.DV_360;
  metadata: Record<string, never>;
}

export interface LinkedInAdsResource extends BaseResource {
  channel: ResourceChannel.LINKED_IN_ADS;
  metadata: Record<string, never>;
}

export interface LinkedInOrganicResource extends BaseResource {
  channel: ResourceChannel.LINKED_IN_ORGANIC;
  metadata: { profileImageUrl?: string };
}

export interface SnapchatAdsResource extends BaseResource {
  channel: ResourceChannel.SNAPCHAT_ADS;
  metadata: Record<string, never>;
}
export interface XandrResource extends BaseResource {
  channel: ResourceChannel.XANDR;
  metadata: Record<string, never>;
}

export interface BingAdsResource extends BaseResource {
  channel: ResourceChannel.BING_ADS;
  metadata: Record<string, never>;
}

export interface CAltResource extends BaseResource {
  channel: ResourceChannel.C_ALT;
  metadata: Record<string, never>;
}

export interface TikTokAdsResource extends BaseResource {
  channel: ResourceChannel.TIK_TOK_ADS;
  metadata: Record<string, never>;
}

export interface TikTokOrganicResource extends BaseResource {
  channel: ResourceChannel.TIK_TOK_ORGANIC;
  metadata: Record<string, never>;
}

export interface KoblerResource extends BaseResource {
  channel: ResourceChannel.KOBLER;
  metadata: Record<string, never>;
}

export interface AdformResource extends BaseResource {
  channel: ResourceChannel.ADFORM;
  metadata: Record<string, never>;
}

export interface GoogleSheetsResource extends BaseResource {
  channel: ResourceChannel.GOOGLE_SHEETS;
  metadata: Record<string, never>;
}

export type Resource =
  | FacebookAdsResource
  | FacebookOrganicResource
  | InstagramOrganicResource
  | GoogleAdsResource
  | GoogleAnalyticsResource
  | GoogleAnalytics4Resource
  | GoogleBigQueryResource
  | GoogleBigQueryAnalyticsResource
  | GoogleSearchConsoleResource
  | YoutubeResource
  | MerchantCenterResource
  | DV360Resource
  | LinkedInAdsResource
  | LinkedInOrganicResource
  | SnapchatAdsResource
  | XandrResource
  | BingAdsResource
  | CAltResource
  | TikTokAdsResource
  | TikTokOrganicResource
  | KoblerResource
  | AdformResource
  | GoogleSheetsResource;

export interface ResourceAccount {
  name: string;
  id: string;
  account?: string; // returned on Google Analytics
  property?: string; // returned on Google Analytics
  propertyId?: string; // returned on Google Analytics
}
interface CreateResourcePayload {
  channel: ResourceChannel;
  externalId: string;
  name: string;
}

function createResource(payload: CreateResourcePayload): Promise<Resource> {
  return api
    .post("/resources/resources/", payload)
    .then((response) => response.data);
}

export function useCreateResourceMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: createResource,
      onError: () => gtmResource.saveCreate("Error"),
      onSuccess: (newResource) => {
        gtmResource.saveCreate("Success");
        queryClient.setQueryData<Resource[]>(["resources", {}], (resources) => [
          ...(resources ?? []),
          newResource,
        ]);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            if (query.queryKey[0] === "resources") {
              return true;
            }
            // Also invalidate any other queries using a resource filter
            const params = query.queryKey?.[1] as object | undefined;
            return (
              params !== undefined &&
              ("resource__in" in params || "not__resource__in" in params)
            );
          },
        });
      },
    }),
  );
}

function listResources({
  queryKey: [, params],
}: QueryFunctionContext<PublicDashboardFilters>): Promise<Resource[]> {
  const channels = new Set(Object.values(ResourceChannel));
  return api
    .get<Resource[]>("/resources/resources/", { params })
    .then((response) => {
      return response.data.filter((resource) => channels.has(resource.channel));
    });
}

export function useResourcesQuery() {
  return reactive(
    useQuery({
      queryKey: ["resources", { ...usePublicDashboardQueryFilters() }] as const,
      queryFn: listResources,
    }),
  );
}

function deleteResource(resourceId: number): Promise<void> {
  return api.delete(`/resources/resources/${resourceId}/`);
}

export function useDeleteResourceMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteResource,
      onError: () => gtmResource.saveDelete("Error"),
      onSuccess: (_, pk) => {
        gtmResource.saveDelete("Success");
        queryClient.setQueriesData<Resource[]>(
          { queryKey: ["resources"] },
          (resources) =>
            resources?.filter((resource: Resource) => resource.pk !== pk),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            const queryKeys = ["resources", "resourceGroups"];
            if (queryKeys.includes(query.queryKey[0] as string)) {
              return true;
            }
            // Also invalidate any other queries using a resource filter
            const params = query.queryKey?.[1] as object | undefined;
            return (
              params !== undefined &&
              ("resource__in" in params || "not__resource__in" in params)
            );
          },
        });
      },
    }),
  );
}

function fetchManually(payload: {
  resources: number[];
  competitorMonitoring: boolean;
  siteScore: boolean;
}): Promise<void> {
  return api
    .post("/resources/fetch_manually/", payload)
    .then((response) => response.data);
}

export function useFetchManuallyMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: fetchManually,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["resources"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitors"],
        });
        queryClient.invalidateQueries({
          queryKey: ["organization"],
        });
      },
    }),
  );
}

export const channelToPath = {
  [ResourceChannel.FACEBOOK_ADS]: "facebook_ads",
  [ResourceChannel.FACEBOOK_ORGANIC]: "facebook_organic",
  [ResourceChannel.INSTAGRAM_ORGANIC]: "instagram_organic",
  [ResourceChannel.GOOGLE_ADS]: "google_ads",
  [ResourceChannel.GOOGLE_ANALYTICS]: "google_analytics",
  [ResourceChannel.GOOGLE_ANALYTICS_4]: "google_analytics_4",
  [ResourceChannel.GOOGLE_BIG_QUERY]: "google_big_query",
  [ResourceChannel.GOOGLE_BIG_QUERY_ANALYTICS]: "google_big_query_analytics",
  [ResourceChannel.GOOGLE_SEARCH_CONSOLE]: "google_search_console",
  [ResourceChannel.YOUTUBE]: "youtube",
  [ResourceChannel.MERCHANT_CENTER]: "merchant_center",
  [ResourceChannel.DV_360]: "dv_360",
  [ResourceChannel.LINKED_IN_ADS]: "linked_in_ads",
  [ResourceChannel.LINKED_IN_ORGANIC]: "linked_in_organic",
  [ResourceChannel.SNAPCHAT_ADS]: "snapchat_ads",
  [ResourceChannel.XANDR]: "xandr",
  [ResourceChannel.BING_ADS]: "bing_ads",
  [ResourceChannel.C_ALT]: "c_alt",
  [ResourceChannel.TIK_TOK_ORGANIC]: "tik_tok_organic",
  [ResourceChannel.TIK_TOK_ADS]: "tik_tok_ads",
  [ResourceChannel.KOBLER]: "kobler",
  [ResourceChannel.ADFORM]: "adform",
  [ResourceChannel.GOOGLE_SHEETS]: "google_sheets",
} as const satisfies Record<ResourceChannel, string>;

function getResourceAccounts({
  queryKey: [, { channel }],
}: QueryFunctionContext<{ channel: ResourceChannel }>): Promise<
  ResourceAccount[]
> {
  const path = `/external_apis/${circumventAdBlocker(
    channelToPath[channel],
  )}_resources/`;
  return api.get(path).then((response) => response.data);
}

export function useResourceAccountsQuery({
  channel,
}: {
  channel: Ref<ResourceChannel>;
}) {
  const queryKey = ["resourceAccounts", { channel }] as const;
  return reactive(useQuery({ queryKey, queryFn: getResourceAccounts }));
}

export interface ResourceGroup {
  pk: number;
  name: string;
  createdAt: string;
  modifiedAt: string;
  user: number;
  resources: number[];
}
interface CreateResourceGroupPayload {
  name: string;
  resources: number[];
}
export type UpdateResourceGroupPayload = Pick<
  ResourceGroup,
  "pk" | "name" | "resources"
>;
function createResourceGroup(
  payload: CreateResourceGroupPayload,
): Promise<ResourceGroup> {
  return api
    .post("/resources/resource_groups/", payload)
    .then((response) => response.data);
}
function updateResourceGroup(
  resourceGroup: UpdateResourceGroupPayload,
): Promise<ResourceGroup> {
  return api
    .put(`/resources/resource_groups/${resourceGroup.pk}/`, resourceGroup)
    .then((res) => res.data);
}

function listResourceGroups(): Promise<ResourceGroup[]> {
  return api
    .get("/resources/resource_groups/")
    .then((response) => response.data);
}
function deleteResourceGroup(resourceGroupId: number): Promise<void> {
  return api.delete(`/resources/resource_groups/${resourceGroupId}/`);
}
export function useResourceGroupsQuery() {
  return reactive(
    useQuery({ queryKey: ["resourceGroups"], queryFn: listResourceGroups }),
  );
}

export function useCreateResourceGroupMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: createResourceGroup,
      onError: () => gtmResourceGroup.saveCreate("Error"),
      onSuccess: (newResourceGroup) => {
        gtmResourceGroup.saveCreate("Success");
        queryClient.setQueryData<ResourceGroup[]>(
          ["resourceGroups"],
          (resourceGroups) => [...(resourceGroups ?? []), newResourceGroup],
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["resourceGroups"] });
      },
    }),
  );
}

export function useUpdateResourceGroupMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateResourceGroup,
      onError: () => gtmResourceGroup.saveEdit("Error"),
      onSuccess: (newResourceGroup) => {
        gtmResourceGroup.saveEdit("Success");
        queryClient.setQueryData<ResourceGroup[]>(
          ["resourceGroups"],
          (resourceGroups) =>
            resourceGroups?.map((resourceGroup) =>
              resourceGroup.pk === newResourceGroup.pk
                ? newResourceGroup
                : resourceGroup,
            ),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["resourceGroups"] });
      },
    }),
  );
}

export function useDeleteResourceGroupMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteResourceGroup,
      onError: () => gtmResourceGroup.saveDelete("Error"),
      onSuccess: (_, pk) => {
        gtmResourceGroup.saveDelete("Success");
        queryClient.setQueriesData<ResourceGroup[]>(
          { queryKey: ["resourceGroups"] },
          (resourceGroups) =>
            resourceGroups?.filter(
              (resourceGroup: ResourceGroup) => resourceGroup.pk !== pk,
            ),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["resourceGroups"] });
      },
    }),
  );
}

function authorizeXandr({
  username,
  password,
  tokenId,
}: {
  username: string;
  password: string;
  tokenId?: number;
}): Promise<void> {
  return api.post(
    "/oauth/xandr/authorize/",
    { username, password },
    { params: { token_id: tokenId } },
  );
}

export function useAuthorizeXandrMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: authorizeXandr,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["resources"] });
        queryClient.invalidateQueries({ queryKey: ["tokens"] });
      },
    }),
  );
}

function authorizeCAlt({
  token,
  tokenId,
}: {
  token: string;
  tokenId?: number;
}): Promise<void> {
  return api.post(
    "/oauth/c_alt/authorize/",
    { token },
    { params: { token_id: tokenId } },
  );
}

export function useAuthorizeCAltMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: authorizeCAlt,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["resources"] });
        queryClient.invalidateQueries({ queryKey: ["tokens"] });
      },
    }),
  );
}

function authorizeKobler({
  token,
  tokenId,
}: {
  token: string;
  tokenId?: number;
}): Promise<void> {
  return api.post(
    "/oauth/kobler/authorize/",
    { token },
    { params: { token_id: tokenId } },
  );
}

export function useAuthorizeKoblerMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: authorizeKobler,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["resources"] });
        queryClient.invalidateQueries({ queryKey: ["tokens"] });
      },
    }),
  );
}

export function getSocialMediaProfileUrl(
  resource: DeepReadonly<
    | FacebookOrganicResource
    | LinkedInOrganicResource
    | InstagramOrganicResource
    | YoutubeResource
    | TikTokOrganicResource
    | GoogleSheetsResource
  >,
): string | undefined {
  switch (resource.channel) {
    case ResourceChannel.FACEBOOK_ORGANIC:
      return `https://www.facebook.com/${resource.externalId}/`;
    case ResourceChannel.LINKED_IN_ORGANIC:
      return `https://www.linkedin.com/company/${resource.externalId}/`;
    case ResourceChannel.INSTAGRAM_ORGANIC: {
      if ("username" in resource.metadata) {
        return `https://www.instagram.com/${resource.metadata.username}/`;
      } else {
        return undefined;
      }
    }
    case ResourceChannel.YOUTUBE:
      return `https://www.youtube.com/channel/${resource.externalId}`;
    case ResourceChannel.TIK_TOK_ORGANIC:
      return `https://www.tiktok.com/@${resource.externalId}`;
    case ResourceChannel.GOOGLE_SHEETS:
      return undefined;
    default:
      assertUnreachable(resource);
  }
}
