import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
  RouteMeta,
  useRoute,
  RouteLocationNormalizedLoaded,
} from "vue-router";
import { useDataFiltersStore } from "@/store/data_filters";
import { authRoutes } from "@/router/auth_routes";
import {
  organizationRoutes,
  publicDashboardRoute,
} from "@/router/organization_settings_routes";
import { websiteRoutes } from "@/router/website_routes";
import { publicReportRoute, reportingRoutes } from "@/router/reporting_routes";
import {
  insightsRoutes,
  recommendationTypeRoute,
} from "@/router/insights_routes";
import { marketingRoutes } from "@/router/marketing_routes";
import { toolsRoutes } from "@/router/tools_routes";
import { watch, computed, watchEffect } from "vue";
import { hasAccessTier, hasRequiredUserRole } from "@/utils/tiers";
import { salesRoutes } from "@/router/sales_routes";
import { useOrganizationQuery } from "@/services/organization";
import { useProfileQuery } from "@/services/user";
import { getLighthouseReportViewerLink } from "@/services/lighthouse";
import { useAuthStore } from "@/store/auth";
import { useRouteAccess } from "@/utils/routes";
import { backendEmailRoutes } from "@/router/backend_email_routes";
function devReferenceRoutes(): RouteRecordRaw[] {
  if (import.meta.env.MODE === "production") {
    return [];
  }
  return [
    {
      path: "icons",
      name: "icons",
      meta: { title: "Icons" },
      component: () => import("@/views/DevReferencePages/IconList.vue"),
    },
    {
      path: "design",
      name: "design",
      meta: { title: "Design" },
      component: () => import("@/views/DevReferencePages/Design.vue"),
    },
    {
      path: "sandbox",
      name: "sandbox",
      meta: { title: "Sandbox" },
      component: () => import("@/views/DevReferencePages/Sandbox.vue"),
      children: [
        {
          path: "open_graph_proxy",
          name: "open_graph_proxy",
          meta: { title: "Open Graph Proxy" },
          component: () =>
            import("@/views/DevReferencePages/Sandbox/OpenGraphProxy.vue"),
        },
        {
          path: "custom_data_import_available_entities",
          name: "custom_data_import_available_entities",
          meta: { title: "Custom Data Import - Available Entities" },
          component: () =>
            import(
              "@/views/DevReferencePages/Sandbox/CustomDataImport/AvailableEntities.vue"
            ),
        },
        {
          path: "custom_data_import_schemas",
          name: "custom_data_import_schemas",
          meta: { title: "Custom Data Import - Schemas" },
          component: () =>
            import(
              "@/views/DevReferencePages/Sandbox/CustomDataImport/Schemas.vue"
            ),
        },
      ],
    },
  ];
}

const oldRedirectRoutes: RouteRecordRaw[] = [
  {
    path: "marketing-performance/details",
    redirect: { name: "ad-performance-base" },
  },
  {
    path: "website-optimization",
    redirect: { name: "page-insights-search" },
    children: [
      {
        path: ":pk",
        redirect: { name: "page-insights-content" },
      },
    ],
  },
  {
    path: "organic-traffic",
    redirect: { name: "search-term-report" },
    children: [
      {
        path: ":id",
        redirect: { name: "search-term-detail" },
      },
    ],
  },
  {
    path: "competitor-monitor",
    redirect: { name: "competitor-overview" },
    children: [
      {
        path: "competitor-overview",
        redirect: { name: "competitor-overview" },
      },
      {
        path: "competitor-posts",
        redirect: { name: "competitor-posts" },
      },
    ],
  },
  {
    path: "website-performance",
    redirect: { name: "website-analytics" },
  },
  {
    path: "website-traffic",
    redirect: { name: "website-analytics" },
  },
  {
    path: "marketing",
    redirect: { name: "ad-performance" },
  },
  {
    path: "site-score/:pk",
    component: {
      setup() {
        const route = useRoute();
        window.location.replace(
          getLighthouseReportViewerLink(Number(route.params.pk)),
        );
      },
    },
  },
  {
    path: "performance-indicators",
    redirect: { name: "goals" },
  },
  {
    path: "/settings/resources",
    redirect: { name: "resources" },
  },
  {
    path: "/settings/merge-resources",
    redirect: { name: "merge-resources" },
  },
  {
    path: "/settings/merge-resources/edit/:pk",
    redirect: { name: "edit" },
  },
  {
    path: "/settings/add-resource",
    redirect: { name: "add-resource" },
  },
  {
    path: "/settings/add-resource/select-channel",
    redirect: { name: "select-channel" },
  },
  {
    path: "/settings/add-resource/xandr-auth",
    redirect: { name: "xandr-auth" },
  },
  {
    path: "marketing-performance",
    redirect: { name: "ad-performance-base" },
  },
  {
    path: "marketing-performance/overview",
    redirect: { name: "ad-performance-overview" },
  },
  {
    path: "marketing-performance/ads",
    redirect: { name: "marketing-performance-ads" },
  },
  {
    path: "content-lab",
    redirect: { name: "create-content" },
  },
  {
    path: "content-lab/content",
    redirect: { name: "content" },
  },
];

export const settingsRoute: RouteRecordRaw = {
  path: "settings",
  name: "settings",
  redirect: { name: "general" },
  meta: {
    title: "Settings",
    text: "Settings",
    description: "Change your organization settings",
    icon: "cogs",
    helpDeskPath: "settings",
  },
  component: () =>
    import("@/views/OrganizationSettings/OrganizationOverview.vue"),
  children: [...organizationRoutes],
};

export const profileRoute: RouteRecordRaw = {
  path: "profile",
  name: "profile",
  meta: {
    title: "Profile",
    text: "Profile",
    description: "Edit information and settings related to your user profile",
    icon: "user",
    helpDeskPath: "settings",
  },
  component: () => import("@/views/UserSettings/UserSettingsBase.vue"),
};

export const createOrganizationRoute: RouteRecordRaw = {
  path: "create-organization",
  name: "create-organization",
  meta: {
    title: "Create Organization",
    text: "Create a new organization",
    description: "Create a new organization, and invite users to it",
    icon: "plus",
    requiredPermissions: ["canOnboard"],
    helpDeskPath: "settings",
  },
  component: () =>
    import("@/views/OrganizationSettings/CreateOrganization.vue"),
};

export const appDocsRoutes: RouteRecordRaw[] = [
  {
    path: "/docs",
    alias: ["/privacy"], // Preserved and redirects to keep existing links out in the wild working
    name: "docs",
    component: () => import("@/views/AppDocs/AppDocs.vue"),
    children: [
      {
        path: ":document",
        name: "docs-document",
        meta: { title: "Documentation" },
        component: () => import("@/views/AppDocs/AppDocs.vue"),
      },
    ],
  },
];

const feedRoutes: RouteRecordRaw[] = [
  {
    path: "feed",
    name: "feed",
    meta: {
      title: "Feed",
      added: "2023-03-23",
      description:
        "Stay on top of the latest updates regarding your sales and marketing.",
      icon: "browser",
      requiredResources: [],
    },
    component: () => import("@/views/Feed/FeedView.vue"),
    children: [
      {
        path: "new",
        name: "feed-new-post",
        component: () => import("@/views/Feed/FeedView.vue"),
      },
    ],
  },
  {
    path: "feed/:id",
    name: "feed-post",
    meta: {
      title: "Feed Post",
      requiredResources: [],
      excludeFromNavbar: true,
    },
    component: () => import("@/views/Feed/FeedPostView.vue"),
  },
] as const;

// !!! IMPORTANT !!!
// Remember to change redirect URLs in the deployment repo envs if changing the root URL
const authedRoutes: RouteRecordRaw[] = [
  ...reportingRoutes,
  ...feedRoutes,
  ...websiteRoutes,
  ...marketingRoutes,
  ...insightsRoutes,
  ...toolsRoutes,
  ...salesRoutes,
  recommendationTypeRoute,
  // dashboardRoute,
  ...oldRedirectRoutes,
  ...devReferenceRoutes(),
  settingsRoute,
  profileRoute,
  {
    path: "notifications",
    name: "notifications",
    meta: { title: "Notifications" },
    component: () => import("@/views/Notifications.vue"),
  },
  createOrganizationRoute,
];

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "base",
    redirect: { name: "feed" },
    children: [
      ...publicReportRoute,
      ...publicDashboardRoute,
      ...authRoutes.map((route) => ({
        ...route,
        meta: {
          ...(route.meta as RouteMeta),
          includeNavbar: false,
          containered: false,
        },
      })),
      ...authedRoutes.map((route) => ({
        ...route,
        meta: { ...(route.meta as RouteMeta), requiresAuth: true },
      })),
      ...appDocsRoutes,
      {
        path: ":pathMatch(.*)*",
        name: "not-found",
        props: (route) => ({
          resourceRequested: route.query.resourceRequested,
        }),
        meta: { title: "404 Not Found" },
        component: () => import("@/views/NotFound.vue"),
      },
      ...backendEmailRoutes(),
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      // If the route name is the same, we only changed a parameter,
      // in which case we don't want to scroll to the top.
      if (from.name !== to.name) {
        return { top: 0 };
      } else {
        // But in marketing performance the same route is used for different "pages",
        // the only difference is the query parameter `level`.
        if (from.query.level !== to.query.level) {
          return { top: 0 };
        }
      }
    }
  },
});

export function useRequiresAuthNavigationGuard(): void {
  const authStore = useAuthStore();
  router.beforeEach((to) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!authStore.isAuthed) {
        return {
          name: "login",
          query: { redirect: to.fullPath },
        };
      }
    }
    return;
  });
}
router.beforeResolve((to) => {
  const store = useDataFiltersStore();
  if (to.redirectedFrom?.query.share) {
    store.$state = JSON.parse(to.redirectedFrom.query.share as string);
  } else if (to.query.share) {
    store.$state = JSON.parse(to.query.share as string);
  }
});

export default router;

export enum NotFoundErrors {
  ROUTE = "ROUTE",
  INVITE = "INVITE",
  ACCESS_TIER = "ACCESS_TIER",
  OTHER = "OTHER",
}
export function directToNotFound(
  res: NotFoundErrors,
  route: RouteLocationNormalizedLoaded,
): void {
  // https://router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
  router.push({
    name: "not-found",
    // preserve current path and remove the first char to avoid the target URL starting with `//`
    params: { pathMatch: route.path.substring(1).split("/") },
    // preserve existing query and hash if any
    query: { ...route.query, resourceRequested: res },
    hash: route.hash,
  });
}

export function watchRouteForAccessTier(): void {
  // Fires once every route change
  const route = useRoute();
  const tier = computed(() => {
    return route.meta.requiredTier;
  });
  const organization = useOrganizationQuery();

  watch(
    [() => organization.data, tier],
    () => {
      if (
        tier.value !== undefined &&
        organization.data &&
        !hasAccessTier(organization.data.subscriptionTier, tier.value)
      ) {
        directToNotFound(NotFoundErrors.ACCESS_TIER, route);
      }
    },
    { immediate: true, deep: true },
  );
}
export function watchRouteForUserRole(): void {
  // Fires once every route change
  const route = useRoute();
  const role = computed(() => {
    return route.meta.requiredUserRole;
  });
  const user = useProfileQuery();

  watch(
    [() => user.data, role],
    () => {
      if (
        role.value !== undefined &&
        user.data &&
        !hasRequiredUserRole(user.data.role, role.value)
      ) {
        directToNotFound(NotFoundErrors.ROUTE, route);
      }
    },
    { immediate: true, deep: true },
  );
}

export function watchRouteForOrganization(): void {
  // Fires once every route change
  const route = useRoute();
  const routeAccess = useRouteAccess(
    computed(() => route),
    true,
  );

  watchEffect(() => {
    if (!routeAccess.show.value) {
      directToNotFound(NotFoundErrors.ROUTE, route);
    }
  });
}

export function watchRouteForResetFilters(): void {
  const route = useRoute();
  const dataFiltersStore = useDataFiltersStore();

  watch(
    () => route.meta,
    (newMeta, oldMeta) => {
      if (
        oldMeta &&
        Object.keys(oldMeta).length &&
        (!oldMeta.text || !newMeta.text || oldMeta.text !== newMeta.text)
      ) {
        dataFiltersStore.resetSharableFilters();
      }
    },
    { deep: true, immediate: true },
  );
}

export function watchRouteForPermissionRequirement(): void {
  const route = useRoute();
  const self = useProfileQuery();

  watch(
    [() => self.data, () => route.meta],
    () => {
      if ("requiredPermissions" in route.meta) {
        if (self.data) {
          for (const permission of route.meta.requiredPermissions ?? []) {
            if (
              permission in self.data &&
              !self.data[permission as keyof typeof self.data]
            ) {
              directToNotFound(NotFoundErrors.ROUTE, route);
              return;
            }
          }
        }
      }
    },
    { immediate: true, deep: true },
  );
}
