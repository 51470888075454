import api from "@/services/api";
import {
  TimeUnit,
  ResourcesDatafilter,
  TagsDataFilter,
  TemporalDataFilter,
  SeparateResourcesFilter,
  DateRangeSelection,
  SelectionTemporalDataFilter,
  TemporalPeriodsType,
} from "@/services/data_filters";
import { ResourceChannel } from "@/services/resources";
import {
  computed,
  ComputedRef,
  MaybeRef,
  reactive,
  ref,
  Ref,
  unref,
} from "vue";
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import {
  useCalculatedResources,
  useDetachedTemporalFilter,
  useDataFilters,
  useDetachedSelectionTemporalFilter,
  useDetachedTagFilter,
} from "@/store/data_filters";
import { QueryFunctionContext } from "@/utils/typing";
import {
  PublicDashboardWidgetFilters,
  usePublicDashboardWidgetQueryFilters,
} from "@/services/public_dashboard";
import { MetricFilterSet, MetricKey } from "@/services/metrics";
import {
  CompetitorPost,
  useCompetitorsQuery,
  competitorById,
  Competitor,
} from "@/services/competitor_monitoring";
import { useResourcesQuery } from "@/services/resources";
import { isCompetitorPost } from "@/views/CompetitorMonitor";
import {
  FacebookOrganicResource,
  InstagramOrganicResource,
  Resource,
} from "@/services/resources";
import { filterCriteriaFormatter } from "@/utils/filterCriteriaFormatter";
import { HeatmapWeekdayData } from "@/utils/heatmap";
import { DateTime } from "luxon";
import {
  CalculatedMetricKey,
  isCalculatedMetric,
  MetricType,
  useCalculatedMetricKeys,
} from "@/services/calculated_metrics";
import { AscendingOrDescending } from "@/components/DataPresentation";

export const SOCIAL_MEDIA_RESOURCE_CHANNELS = [
  ResourceChannel.LINKED_IN_ORGANIC,
  ResourceChannel.FACEBOOK_ORGANIC,
  ResourceChannel.INSTAGRAM_ORGANIC,
  ResourceChannel.YOUTUBE,
  ResourceChannel.TIK_TOK_ORGANIC,
  ResourceChannel.GOOGLE_SHEETS,
] as const satisfies ResourceChannel[];

export type SocialMediaResourceChannel =
  (typeof SOCIAL_MEDIA_RESOURCE_CHANNELS)[number];

const socialMediaPostMetrics = [
  "impressions",
  "clicks",
  "comments",
  "clickthroughRate",
  "shares",
  "engagement",
  "engagementRate",
  "reactions",
  "pageViewsPerSession",
  "bounceRate",
  "conversions",
] as const satisfies MetricKey[];

export function useSocialMediaPostMetrics() {
  const calculatedMetricKeys = useCalculatedMetricKeys(
    MetricType.SOCIAL_MEDIA,
    socialMediaPostMetrics,
  );
  return computed(() => [
    ...socialMediaPostMetrics,
    ...calculatedMetricKeys.value,
  ]);
}

export type SocialMediaPostMetric =
  | (typeof socialMediaPostMetrics)[number]
  | CalculatedMetricKey;

const socialMediaPageMetrics = [
  "impressions",
  "clicks",
  "clickthroughRate",
  "followers",
  "engagement",
  "engagementRate",
  "shares",
  "comments",
  "reactions",
] as const satisfies MetricKey[];

export function useSocialMediaPageMetrics() {
  const calculatedMetricKeys = useCalculatedMetricKeys(
    MetricType.SOCIAL_MEDIA,
    socialMediaPageMetrics,
  );
  return computed(() => [
    ...socialMediaPageMetrics,
    ...calculatedMetricKeys.value,
  ]);
}

export type SocialMediaPageMetric =
  | (typeof socialMediaPageMetrics)[number]
  | CalculatedMetricKey;

export const socialMediaPostSortKeys = [
  ...socialMediaPostMetrics,
  "publishedAt",
] as const satisfies (keyof Post)[];

export function useSocialMediaPostSortKeys() {
  const socialMediaPostMetrics = useSocialMediaPostMetrics();
  return computed(
    () =>
      [
        ...socialMediaPostSortKeys,
        ...socialMediaPostMetrics.value.filter(isCalculatedMetric),
      ] as const satisfies (keyof Post)[],
  );
}

export type SocialMediaPostSortKey =
  | (typeof socialMediaPostSortKeys)[number]
  | CalculatedMetricKey;

export interface PageMetricByDateAndPlatformDataset {
  label: string;
  channel: SocialMediaResourceChannel;
  resource: string | null;
  data: (number | null)[];
  period: TemporalPeriodsType;
}

export interface PageMetricByDateAndPlatform {
  labels: string[];
  datasets: PageMetricByDateAndPlatformDataset[];
}

export type PageMetricsByDateAndPlatform = Record<
  SocialMediaPageMetric,
  PageMetricByDateAndPlatform
>;
export type TemporalPageMetricsByDateAndPlatform = Record<
  TemporalPeriodsType,
  PageMetricsByDateAndPlatform
>;

function listPageMetricsByDateAndPlatform({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    timeUnit: TimeUnit;
  } & SeparateResourcesFilter &
    TemporalDataFilter &
    ResourcesDatafilter &
    PublicDashboardWidgetFilters
>): Promise<TemporalPageMetricsByDateAndPlatform> {
  return api
    .get("/social_media/page_metrics_by_date_and_platform/", { params })
    .then((response) => response.data);
}

export function usePageMetricsByDateAndPlatformQuery({
  selectedResourceIds,
  temporalFilter,
  timeUnit = ref(TimeUnit.DAY),
  enabled,
  useGlobalFilters = true,
}: {
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  timeUnit?: Ref<TimeUnit>;
  enabled?: Ref<boolean>;
  useGlobalFilters?: boolean;
} = {}) {
  const queryKey = [
    "pageMetricsByDateAndPlatformQuery",
    {
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useDataFilters(["separateResources"], useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      timeUnit,
    },
  ] as const;
  return reactive(
    useQuery({ queryKey, queryFn: listPageMetricsByDateAndPlatform, enabled }),
  );
}
export const usePageMetricsByDateAndPlatformQueryWithMetrics = {
  query: usePageMetricsByDateAndPlatformQuery,
  metrics: socialMediaPageMetrics,
};

export interface PageMetricsByComparisonPeriod
  extends Record<SocialMediaPageMetric, number | null> {
  label: "current" | "previous";
}

export interface PageMetricsByComparison {
  currentPeriod: PageMetricsByComparisonPeriod;
  previousPeriod: PageMetricsByComparisonPeriod;
}

function listPageMetricsByComparison({
  queryKey: [, params],
}: QueryFunctionContext<
  TemporalDataFilter & ResourcesDatafilter & PublicDashboardWidgetFilters
>): Promise<PageMetricsByComparison> {
  return api
    .get("/social_media/page_metrics_compared_to_previous_period/", { params })
    .then((response) => response.data);
}

export function usePageMetricsByComparisonQuery({
  selectedResourceIds,
  temporalFilter,
  enabled,
  useGlobalFilters = true,
}: {
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  enabled?: Ref<boolean>;
  useGlobalFilters?: boolean;
} = {}) {
  const queryKey = [
    "pageMetricsByComparison",
    {
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
    },
  ] as const;
  return reactive(
    useQuery({ queryKey, queryFn: listPageMetricsByComparison, enabled }),
  );
}
export const usePageMetricsByComparisonQueryWithMetrics = {
  query: usePageMetricsByComparisonQuery,
  metrics: socialMediaPageMetrics,
};

export interface OpenGraphData {
  link: string;
  url: string;
  type: string | null;
  title: string | null;
  image: string | null;
  description: string | null;
  siteName: string | null;
}

export interface PostAsset {
  mediaType: string;
  mediaUrl: string;
  targetUrl: string;
}

export enum PostAssetImageTypes {
  image = "image",
  photo = "photo",
  carousel_album__image = "carousel_album__image",
  album__image = "album__image",
  album__photo = "album__photo",
  multi_share__share = "multi_share__share",
  multi_share_no_end_card__share = "multi_share_no_end_card__share",
  profile_media = "profile_media",
  profile_media__photo = "profile_media__photo",
}

export enum PostAssetPageUpdateTypes {
  cover_photo = "cover_photo",
  profile_media = "profile_media",
}

export enum PostAssetVideoTypes {
  video = "video",
  reels = "reels",
  carousel_album__video = "carousel_album__video",
}

export enum PostAssetVideoThumbnailTypes {
  video_direct_response__video = "video_direct_response__video",
  animated_image_video__video = "animated_image_video__video",
}

export enum PostAssetLinkTypes {
  link = "link",
}

export enum PostAssetShareTypes {
  share = "share",
}

export enum VideoPostTypes {
  video = "video",
  reels = "reels",
}
export enum DocumentPostTypes {
  document = "document",
}

export enum CollectionPostTypes {
  carousel = "carousel",
  album = "album",
  multi_share = "multi_share",
  multi_share_no_end_card = "multi_share_no_end_card",
}

export enum ImagePostTypes {
  image = "image",
  photo = "photo",
}

export enum VideoThumbnailPostTypes {
  video_inline = "video_inline",
  video_direct_response = "video_direct_response",
  animated_image_video = "animated_image_video",
}

export enum LinkPostTypes {
  link = "link",
}

export enum SharePostTypes {
  share = "share",
}

export enum FeedPostTypes {
  feed = "feed",
}

export enum PageUpdatePostTypes {
  cover_photo = "cover_photo",
  profile_media = "profile_media",
}

export enum PostType {
  IMAGE = "image",
  VIDEO = "video",
  VIDEO_THUMBNAIL = "video_thumbnail",
  DOCUMENT = "document",
  COLLECTION = "collection",
  LINK = "link",
  SHARE = "share",
  FEED = "feed",
  PAGE_UPDATE = "page_update",
  UNKNOWN = "unknown",
}

export interface Post {
  pk: number;
  resource: number;
  channel: SocialMediaResourceChannel;
  externalId: string;
  publishedAt: string | null;
  text: string;
  assets: PostAsset[];
  postType: string;
  destinationUrl: string;
  permalinkUrl: string | null;
  openGraphData: OpenGraphData[];
  isSponsored: boolean;
  impressions: number;
  clicks: number;
  comments: number;
  reactions: number;
  shares: number;
  clickthroughRate: number | null;
  engagement: number;
  engagementRate: number | null;
  pageViewsPerSession: number | null;
  conversions: number | null;
  bounceRate: number | null;
  [calculatedMetric: CalculatedMetricKey]: number | null;
  organicImpressions: number;
  organicClicks: number;
  organicComments: number;
  organicReactions: number;
  organicShares: number;
  organicClickthroughRate: number | null;
  organicEngagement: number;
  organicEngagementRate: number | null;
  sponsoredImpressions: number;
  sponsoredClicks: number;
  sponsoredComments: number;
  sponsoredReactions: number;
  sponsoredShares: number;
  sponsoredClickthroughRate: number | null;
  sponsoredEngagement: number;
  sponsoredEngagementRate: number | null;
  tags: number[];
  extra?: {
    title?: string;
  };
}

export interface PostsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Post[];
}

type PostsParams = {
  filterCriteria?: MetricFilterSet[];
  page?: number | undefined;
  pageSize: number | undefined;
  ordering: AscendingOrDescending<SocialMediaPostSortKey> | undefined;
  pks: number[] | undefined;
} & SelectionTemporalDataFilter &
  ResourcesDatafilter &
  TagsDataFilter;

function listPosts({
  queryKey: [, params],
  ...context
}:
  | QueryFunctionContext<PostsParams, number | undefined>
  | QueryFunctionContext<PostsParams>): Promise<PostsResponse> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);
  return api
    .get("/social_media/posts/", {
      params: {
        ...params,
        ...filterCriteria,
        page: "pageParam" in context ? context.pageParam : params.page,
        pageSize: params.pageSize ?? "null",
      },
    })
    .then((response) => response.data);
}

export function usePostsQuery({
  filterCriteria,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  page,
  pageSize,
  ordering,
  pks,
  search,
  useGlobalFilters = true,
  enabled,
}: {
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  page?: Ref<number>;
  pageSize?: Ref<number>;
  ordering?: Ref<AscendingOrDescending<SocialMediaPostSortKey>>;
  pks?: Ref<number[]>;
  search?: Ref<string>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
} = {}) {
  const queryKey = [
    "posts",
    {
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      filterCriteria,
      page,
      pageSize,
      ordering,
      pks,
      search,
    },
  ] as const;
  return reactive(useQuery({ queryKey, queryFn: listPosts, enabled }));
}

export function usePostsInfiniteQuery({
  filterCriteria,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  pageSize,
  ordering,
  pks,
  search,
  useGlobalFilters,
  enabled,
}: {
  filterCriteria?: Ref<MetricFilterSet[]>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  pageSize: MaybeRef<number>;
  ordering: Ref<AscendingOrDescending<SocialMediaPostSortKey>>;
  pks?: Ref<number[]>;
  search?: Ref<string>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
}) {
  const queryKey = [
    "posts",
    {
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      filterCriteria,
      pageSize,
      ordering,
      pks,
      search,
    },
  ] as const;
  return reactive(
    useInfiniteQuery({
      queryKey,
      queryFn: listPosts,
      initialPageParam: undefined,
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : undefined,
      enabled,
    }),
  );
}

function getPost({
  queryKey: [, { pk }],
}: QueryFunctionContext<{ pk: number }>): Promise<Post> {
  return api
    .get(`/social_media/posts/${pk}/`)
    .then((response) => response.data);
}

export function useSinglePostQuery({ pk }: { pk: Ref<number> }) {
  const queryClient = useQueryClient();
  const queryKey = ["post", { pk }] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: getPost,
      initialData: () => {
        return queryClient
          .getQueriesData<PostsResponse | undefined>({ queryKey: ["posts"] })
          .flatMap(([_, postsResponse]) => postsResponse?.results ?? [])
          .find((post) => post.pk === pk.value);
      },
    }),
  );
}

export function determinePostType(
  inputPost: Post | CompetitorPost | ComputedRef<Post | CompetitorPost>,
): ComputedRef<PostType> {
  return computed(() => {
    const post = unref(inputPost);
    if (post && post.postType) {
      const media = post.postType.toLowerCase();

      if (media in ImagePostTypes) {
        return PostType.IMAGE;
      } else if (media in VideoPostTypes) {
        return PostType.VIDEO;
      } else if (media in DocumentPostTypes) {
        return PostType.DOCUMENT;
      } else if (media in LinkPostTypes) {
        return PostType.LINK;
      } else if (media in SharePostTypes) {
        return PostType.SHARE;
      } else if (media in CollectionPostTypes) {
        return PostType.COLLECTION;
      } else if (media in VideoThumbnailPostTypes) {
        return PostType.VIDEO_THUMBNAIL;
      } else if (media in PageUpdatePostTypes) {
        return PostType.PAGE_UPDATE;
      } else if (media in FeedPostTypes) {
        if (post.assets.length > 1) {
          return PostType.COLLECTION;
        } else if (post.assets.length === 1) {
          if (post.assets[0].mediaType.toLowerCase() in PostAssetImageTypes) {
            return PostType.IMAGE;
          } else if (
            post.assets[0].mediaType.toLowerCase() in PostAssetVideoTypes
          ) {
            return PostType.VIDEO;
          }
        }
      } else if (post.openGraphData[0]) {
        return PostType.LINK;
      }
    }
    return PostType.UNKNOWN;
  });
}

export function getSocialMediaProfileImageUrl(
  inputPost: Post | CompetitorPost | ComputedRef<Post | CompetitorPost>,
): ComputedRef<string | undefined> {
  const resources = useResourcesQuery();
  const competitors = useCompetitorsQuery({
    enabled: computed(() => isCompetitorPost(unref(inputPost))),
  });
  return computed(() => {
    const post = unref(inputPost);
    if (isCompetitorPost(post)) {
      const competitor = competitorById(post.competitor, competitors);
      if (post.channel === ResourceChannel.FACEBOOK_ORGANIC) {
        return competitor?.facebookProfileImageUrl ?? undefined;
      } else if (post.channel === ResourceChannel.INSTAGRAM_ORGANIC) {
        return competitor?.instagramProfileImageUrl ?? undefined;
      } else {
        return "";
      }
    } else {
      const resource = resources.data?.find(
        (resource) => resource.pk === (post as Post).resource,
      ) as FacebookOrganicResource | InstagramOrganicResource | undefined;
      return resource?.metadata.profileImageUrl ?? undefined;
    }
  });
}

export function getSocialMediaProfileObject(
  inputPost: Post | CompetitorPost | ComputedRef<Post | CompetitorPost>,
): ComputedRef<Resource | Competitor | undefined> {
  const resources = useResourcesQuery();
  const competitors = useCompetitorsQuery({
    enabled: computed(() => isCompetitorPost(unref(inputPost))),
  });
  return computed(() => {
    const post = unref(inputPost);
    if (isCompetitorPost(post)) {
      return competitorById(post.competitor, competitors);
    }
    return resources.data?.find(
      (resource) => resource.pk === (post as Post).resource,
    );
  });
}

export type PostFrequency = HeatmapWeekdayData;

export function listPostFrequency({
  queryKey: [, params],
}: QueryFunctionContext<
  SelectionTemporalDataFilter & TagsDataFilter & ResourcesDatafilter
>): Promise<PostFrequency> {
  return api
    .get(`/social_media/post_frequency/`, {
      params: { ...params, timeZone: DateTime.local().zoneName },
    })
    .then((response) => response.data);
}

export function usePostFrequencyQuery({
  includedTags,
  excludedTags,
  selectedResourceIds,
  temporalFilter,
  useGlobalFilters = true,
  enabled,
}: {
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
} = {}) {
  const queryKey = [
    "competitorPostFrequency",
    {
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listPostFrequency,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}
